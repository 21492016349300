@charset "utf-8";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");

/*------------------------------------------------------------
Project Name:HR-Clinician;
------------------------------------------------------------*/
/* Variables */
:root {
  --primary: #0097a9;
  --primaryDark: #006976;
  --primaryLight: #f1fcfd;
  --lightText: #a3a3a3;
  --darkText: #3e3e3e;
  --blackText: #000000;
  --whiteBg: #ffffff;
  --bodyBg: #f9f9f9;
  --lightBorderColor: #e9e9e9;
  --darkBorderColor: #d1d1d1;
  --lightWarning: #ecce62;
  --lightPurple: #9695c5;
  --lightSkyBlue: #b8deed;
  --colorA7D8ED: #a7d8ed;
  --colorF4E5B0: #f4e5b0;
  --colorC1F0DF: #c1f0df;
  --colorF6D5CD: #f6d5cd;
  --colorEBEBEB: #ebebeb;
  --bg-F1F1F1: #f1f1f1;
  --colorB2E0EF: #b2e0ef;
  --color324B58: #324b58;
  --colorFF5353: #ff5353;
  --color9BB6D5: #9bb6d5;
  --colorFDA53D: #fda53d;
  --color-F8824F: #f8824f;
  --color738EB7: #738eb7;
  --colorFF9330: #ff9330;
  --color9A895D: #9a895d;
}
/*
------------------------------------------------------------
Project Name : Health Reach;
------------------------------------------------------------
@charset "utf-8";

/* Reset 
--------------------------------------------------------------------------------------------------------*/

/* 
http://meyerweb.com/eric/tools/css/reset/
v2.0 | 20110126
License: none (public domain)
*/

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

/* General 
--------------------------------------------------------------------------------------------------------*/
* {
  outline: none !important;
}

html,
body {
  min-width: 375px;
  min-height: 100vh;
  /* scroll-behavior: smooth; */
}

body {
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #757575;
  -ms-overflow-style: scrollbar;
  -webkit-font-smoothing: subpixel-antialiased;
  font-family: 'Inter', sans-serif;
}

.wrapper {
  min-height: 100vh;
}

a {
  color: #000;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.2em;
  padding: 0;
}

h1 span,
h2 span,
h3 span,
h4 span,
h5 span,
h6 span {
  display: block;
}

input[type="text"],
input[type="email"],
input[type="password"],
textarea {
  -webkit-appearance: none;
  appearance: none;
}

.form-control::-webkit-input-placeholder {
  color: #9c9c9c;
}

.form-control::-moz-placeholder {
  color: #9c9c9c;
}

.form-control:-ms-input-placeholder {
  color: #9c9c9c;
}

.form-control:-moz-placeholder {
  color: #9c9c9c;
}

::-moz-focus-inner {
  border: 0;
}

select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

option:not(:checked) {
  color: black;
}

p {
  padding: 0;
}

.break-space{white-space: break-spaces !important}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

body::-webkit-scrollbar {width: 8px;}
body::-webkit-scrollbar-thumb {border: 0;background-color: #b2b2b2 ;border-radius: 12px;}
.footer-modal .modal-body::-webkit-scrollbar {width: 8px;}
.footer-modal .modal-body::-webkit-scrollbar-thumb {border: 0;background-color: #b2b2b2 ;border-radius: 12px;}

/* Loader
****************************************************************************/
.loader-wrapper{ position: fixed; top: 0; bottom: 0; left: 0; right: 0; z-index: 9999; background: rgba(0,0,0,0.4); display: flex; align-items: center; justify-content: center; }


.user-dropdown .MuiPopover-paper{box-shadow: 0px 0px 15px 1px rgba(38, 50, 56, 0.2);border-radius: 8px;}
.user-dropdown .MuiMenuItem-root{display: flex;align-items: center;}
.user-dropdown .user-profile{display: block;width: 49px;height: 49px;border-radius: 50%;margin-right: 13px;}


/* Common Css
--------------------------------------------------------------------------------------------------------*/
.frame-block {background: #FFFFFF;-webkit-box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.16);box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.16);border-radius: 10px;display: flex;align-items: center;padding: 15px 20px;font-size: 12px;line-height: 14px;color: #757575;position: absolute; overflow: hidden; border: 1px solid transparent;}
.frame-block:hover{border-color: #0097A9;}
.frame-block .svg-wrap {margin-right: 17px;}
.frame-block .svg-wrap img {display: block;}
.frame-block strong {font-weight: 700;color: #333333;display: block;}
.frame-block span {display: block;}
.frame-block::after{content: '';display: block;position: absolute; border-radius: 10px; left: 0;right: 0;top: 0;height: 100%; width: 100%; background-color: #ddf4f8; transition: all 700ms ease; z-index: -1;clip-path: circle(1% at 0% 100%)}
.frame-block:hover::after{clip-path: circle(141.4% at 100% 100%)}


.custom-spinner{position: relative;height: 100vh;width: 100%;display: flex;align-items: center;justify-content: center;}
.scroll-anchor{display: flex;visibility: hidden;top: -150px;position: relative;}

.drawer-block a{display: block;padding: 15px 20px;text-align: left;margin-bottom: 4px;}
.drawer-block a.active{background-color: #0097a9;color: #FFFFFF;}
.drawer-block a:not(.active):hover{background-color: rgba(0, 0, 0, 0.08);}
.drawer-logo-block{display: block;}
.drawer-logo-block img{display: block;}

/* Button
--------------------------------------------------------------------------------------------------------*/
.MuiButton-containedPrimary{position: relative;overflow: hidden;z-index: 1;}
.MuiButton-containedPrimary::after{content: '';display: block;position: absolute;top: 0;left: 0;right: 0;bottom: 0;background-color: #006c79; z-index: -1; transition: all 700ms ease;clip-path: circle(1% at 0% 100%)}
.MuiButton-containedPrimary:hover::after{clip-path: circle(141.4% at 100% 100%)}

.MuiButton-outlinedPrimary{position: relative;overflow: hidden;z-index: 1;}
.MuiButton-outlinedPrimary::after{content: '';display: block;position: absolute;top: 0;left: 0;right: 0;bottom: 0; height: 100%; width: 0; background-color: #C0E8EC; z-index: -1; transition: all 500ms ease;}
.MuiButton-outlinedPrimary:hover::after{width: 100%;}




/* Header
--------------------------------------------------------------------------------------------------------*/
.header {position: fixed;top: 0;left: 0;right: 0;-webkit-transition: all 300ms ease;transition: none !important;z-index: 1;}
.header .MuiContainer-root {max-width: 1300px;}
.header .MuiToolbar-root{padding: 0;}
.header .navbar-brand {display: block;margin-left: -17px;}
.header .navbar-brand img {display: block;}
.header .navbar-nav{margin-left: auto;}
.header .navbar-nav .nav-link {font-weight: 500;font-size: 16px;line-height: 20px;color: #333333;position: relative;padding: 10px;margin-right: 14px;-webkit-transition: all 300ms ease;transition: all 300ms ease;text-transform: capitalize;}
.header .navbar-nav .nav-link:after {content: '';height: 1px;width: 0;background-color: transparent;position: absolute;bottom: 0;left: 50%;right: 0;-webkit-transition: all 300ms ease;transition: all 300ms ease;}

.header .navbar-nav .nav-link:hover, .header .navbar-nav .nav-link:focus {color: #0097A9;}
.header .navbar-nav .nav-link:hover::after, .header .navbar-nav .nav-link:focus::after {width: 100%;left: 0;background-color: #0097A9;}
.header .navbar-nav .nav-link.active {color: #0097A9;}
.header .navbar-nav .nav-link.active::after {background-color: #0097A9;width: 100%;left: 0;}
.header .nav-toggler{width: 44px;height: 44px;margin-right: 8px;}
.header .nav-toggler img{display: block;height: 24px;width: 24px;}
.header .header-inner-block{padding: 18px 0; transition: all 300ms ease;}
.page-scrolled .header-inner-block {background-color: #FFFFFF;padding: 6px 0;}
.page-scrolled .header {box-shadow: 0px 2px 30px -1px rgba(0,0,0,0.01), 0px 4px 5px 0px rgba(0,0,0,0.1), 0px 1px 10px 0px rgba(0,0,0,0.1);}

/* Hero
--------------------------------------------------------------------------------------------------------*/
.hero {padding-top: 123px;background: linear-gradient(90.35deg, #DFFCFF 0.3%, #FFF6E9 99.72%);height: 700px;overflow: hidden;}
.hero .MuiContainer-maxWidthLg {max-width: 1310px;}
.hero .hero-content {padding-top: 103px;}
.hero .hero-content h2, .hero .hero-content .h2 {font-weight: 700;font-size: 56px;line-height: 68px;color: #333333;padding-bottom: 14px;text-transform: capitalize;}
.hero .hero-content h2 strong, .hero .hero-content .h2 strong {color: #0097A9;font-weight: 700;}
.hero .hero-content h6, .hero .hero-content .h6 {max-width: 614px;font-size: 20px;line-height: 32px;color: #666666;padding-bottom: 22px;}
.hero .hero-content .find-link {display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;    -ms-flex-align: center;        align-items: center;font-weight: 600;font-size: 20px;line-height: 24px;color: #666666;}
.hero .hero-content .find-link img {display: block;margin-right: 6px;}
.hero .btn-groups {display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;    -ms-flex-align: center;        align-items: center;gap: 24px;margin-top: 30px;}
.hero .btn-groups .MuiButton-outlined {display: -webkit-box;display: -ms-flexbox;display: flex;align-items: center;}
.hero .btn-groups .MuiButton-outlined img {display: block;margin-left: 8px;}
.hero .hero-mockup {position: relative;z-index: 0;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;    -ms-flex-align: center;        align-items: center;-webkit-box-pack: center;    -ms-flex-pack: center;        justify-content: center;height: 475px;}
.hero .hero-mockup .circle-layer {width: 474px;height: 474px;background-color: #C0E8EC;border-radius: 50%;position: absolute;z-index: -1;border: 44px solid #DEF1F3;}
.hero .hero-mockup .circle-outer-layer {width: 600px;height: 600px;background-color: rgba(222,241,243, 0.9);border-radius: 50%;position: absolute;z-index: -2;}
.hero .hero-mockup .hero-frame {display: block;height: 325px;border-radius: 10px;max-width: 100%;filter: drop-shadow(0px 8px 24px rgba(0, 0, 0, 0.16));}
.hero .hero-mockup .frame-block.online {top: 45px;left: -25px;}
.hero .hero-mockup .frame-block.fear {bottom: 20px;left: -8px;-webkit-box-orient: vertical;-webkit-box-direction: normal;    -ms-flex-direction: column;        flex-direction: column;text-align: center;padding: 18px 30px;}
.hero .hero-mockup .frame-block.fear .svg-wrap {margin-bottom: 10px;margin-right: 0;}
.hero .hero-mockup .frame-block.bill {bottom: 102px;right: -82px;}

/* About Us
--------------------------------------------------------------------------------------------------------*/
.about-us {padding: 140px 0 88px;overflow: hidden;}
.about-us .MuiContainer-maxWidthLg {max-width: 1300px;}
.about-us .block-layer {position: relative;width: 408px;height: 480px;z-index: 0;margin-left: 100px;}
.about-us .block-layer .circle {width: 135px;height: 135px;border-radius: 50%;position: absolute;z-index: -1;}
.about-us .block-layer .circle.top {background-color: #C0E8EC;top: -50px;left: -30px;}
.about-us .block-layer .circle.bottom {background-color: #FFF0F0;bottom: -50px;right: -30px;}
.about-us .color-layer {background-color: #DEF1F3;border-radius: 10px;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;    -ms-flex-align: center;        align-items: center;-webkit-box-pack: center;    -ms-flex-pack: center;        justify-content: center;position: relative;}
.about-us .frame-block {padding: 12px 14px;}
.about-us .frame-block .svg-wrap {margin-right: 0;margin-bottom: 4px;}
.about-us .frame-block.heart {-webkit-box-orient: vertical;-webkit-box-direction: normal;    -ms-flex-direction: column;        flex-direction: column;text-align: center;top: 80px;left: -50px;z-index: 1;}
.about-us .frame-block.mental {-webkit-box-orient: vertical;-webkit-box-direction: normal;    -ms-flex-direction: column;        flex-direction: column;text-align: center;bottom: 80px;right: -50px;}
.about-us .section-title {padding-bottom: 22px;padding-top: 23px;}
.about-us .about-us-content{padding-left: 8px;}
.about-us ul li {line-height: 26px;color: #333333;margin-bottom: 20px;max-width: 604px;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: start;    -ms-flex-align: start;        align-items: flex-start;}
.about-us ul li::before {content: '';display: -webkit-inline-box;display: -ms-inline-flexbox;display: inline-flex;width: 24px;height: 24px;background-image: url("../images/list-check-ic.svg");background-position: center;background-repeat: no-repeat;-ms-flex-negative: 0;    flex-shrink: 0;margin-right: 12px;margin-top: 2px;}

/* How It Works
--------------------------------------------------------------------------------------------------------*/
.how-it-works {padding: 70px 0;overflow: hidden;}
.how-it-works .section-title {text-align: center;text-transform: capitalize;padding-bottom: 51px;}
.how-it-works .MuiContainer-maxWidthLg {max-width: 1346px;}
.primary-slider .card-outer {padding: 4px 16px;height: 100%;}
.primary-slider .card {border: 0;background: #FFFFFF;-webkit-box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.05);        box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.05);border-radius: 10px;padding: 0;height: 100%;}
.primary-slider .card .img-block {display: block;position: relative;height: 150px;border-radius: 10px 10px 0 0;overflow: hidden;}
.primary-slider .card .img-block span {position: absolute;width: 100%;height: 100%;-webkit-transition: all 300ms ease;transition: all 300ms ease;}
.primary-slider .card .img-block span img {display: block;width: 100%;height: 100%;-o-object-fit: cover;   object-fit: cover;}
.primary-slider .card:hover .img-block span {-webkit-transform: scale(1.1);        transform: scale(1.1);}
.primary-slider .card-content {padding: 14px 10px 14px 14px;}
.primary-slider .card-content h6, 
.primary-slider .card-content .h6 {font-weight: 600;color: #333333;padding-bottom: 8px;text-transform: capitalize;font-size: 16px;line-height: 24px;}
.primary-slider .card-content p {font-size: 14px;line-height: 20px;color: #757575;min-height: 120px;}
.primary-slider .slick-arrow.slick-prev {background-color: #FFFFFF; box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.08);border-radius: 50%;width: 38px;height: 38px;display: -ms-grid;display: grid;place-content: center;-webkit-transition: all 300ms ease;transition: all 300ms ease;top: auto;bottom: -82px;left: calc(50% - 45px);}
.primary-slider .slick-arrow.slick-prev::before {display: none;}
.primary-slider .slick-arrow.slick-prev svg {display: block;stroke: #0097A9;}
.primary-slider .slick-arrow.slick-prev:hover, .primary-slider .slick-arrow.slick-prev:focus {background-color: #0097A9;}
.primary-slider .slick-arrow.slick-prev:hover svg, .primary-slider .slick-arrow.slick-prev:focus svg {stroke: #FFFFFF;}
.primary-slider .slick-arrow.slick-next {background-color: #FFFFFF; box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.08);border-radius: 50%;width: 38px;height: 38px;display: -ms-grid;display: grid;place-content: center;-webkit-transition: all 300ms ease;transition: all 300ms ease;top: auto;bottom: -82px;right: calc(50% - 45px);}
.primary-slider .slick-arrow.slick-next::before {display: none;}
.primary-slider .slick-arrow.slick-next svg {display: block;stroke: #0097A9;}
.primary-slider .slick-arrow.slick-next:hover, .primary-slider .slick-arrow.slick-next:focus {background-color: #0097A9;}
.primary-slider .slick-arrow.slick-next:hover svg, .primary-slider .slick-arrow.slick-next:focus svg {stroke: #FFFFFF;}

.how-it-works .primary-slider .slick-track{display: flex;}
.how-it-works .primary-slider .slick-slide{height: inherit;}
.how-it-works .primary-slider .slick-slide > div{height: 100%;}

/* comparison table
--------------------------------------------------------------------------------------------------------*/
.comparison-table {padding: 90px 0; overflow: hidden;}
.comparison-table .section-title {text-align: center;text-transform: capitalize;padding-bottom: 51px;}
.comparison-table .MuiContainer-maxWidthLg {max-width: 1308px;}



/* practice with us
--------------------------------------------------------------------------------------------------------*/
.practice-with-us {padding: 70px 0; overflow: hidden;}
.practice-with-us .MuiContainer-maxWidthLg {max-width: 1470px;}
.practice-with-us .section-title {text-transform: capitalize;padding-bottom: 20px;}
.practice-with-us .section-info{padding-bottom: 112px;}
.practice-with-us .practice-mockup{margin-top: 92px;}
.practice-with-us .practice-mockup-block{margin-left: 136px;}
.practice-with-us .block-layer {position: relative;height: 330px;z-index: 0;}
.practice-with-us .block-layer .circle {width: 135px;height: 135px;border-radius: 50%;position: absolute;z-index: -1;}
.practice-with-us .block-layer .circle.top {background-color: #C0E8EC;top: -50px;left: -30px;}
.practice-with-us .block-layer .circle.bottom {background-color: #FFF0F0;bottom: -50px;right: -30px;}
.practice-with-us .color-layer {position: relative;display: flex;align-items: center;justify-content: center;}
.practice-with-us .frame-block {padding: 13px 20px;flex-direction: column;text-align: center;}
.practice-with-us .frame-block .svg-wrap {margin-right: 0;margin-bottom: 4px;}
.practice-with-us .frame-block strong { margin-bottom: 4px;}
.practice-with-us .frame-block.conference {top: -40px;left: 50%; transform: translateX(-50%);z-index: 1;}
.practice-with-us .frame-block.clock {bottom: -40px;left: 50px;}
.practice-with-us .frame-block.medication {bottom: 70px;right: -50px;}
.practice-with-us .accordion-block{padding-left: 88px;}




/* Primary accordion
--------------------------------------------------------------------------------------------------------*/
.primary-accordion .MuiAccordionDetails-root ul li{display: flex;align-items: flex-start;font-size: 14px;line-height: 20px;color: #666666;margin-bottom: 12px;max-width: 440px;}
.primary-accordion .MuiAccordionDetails-root ul li:last-child{margin-bottom: 0;}
.primary-accordion .MuiAccordionDetails-root ul li::before{content: '';display: flex;width: 6px;height: 6px;border-radius: 50%;background-color: #666666;margin-right: 10px;margin-top: 7px; flex-shrink: 0;}



/* other frameworks
--------------------------------------------------------------------------------------------------------*/
.other-frameworks{padding: 28px 0 50px; overflow: hidden; background: linear-gradient(90.35deg, #DFFCFF 0.3%, #FFF6E9 99.72%);border: 1px solid #EEEEEE;border-radius: 10px;}
.other-frameworks .section-info{text-align: center;padding-bottom: 42px;}
.other-frameworks .logo-block a{display: block;}
.other-frameworks .logo-block a img{display: block;}
.other-frameworks .logo-block{display: flex;align-items: center;justify-content: center;}
.other-frameworks .MuiContainer-maxWidthLg{max-width: 1248px}


/* Testimonials
--------------------------------------------------------------------------------------------------------*/
.testimonials{padding: 100px 0;overflow: hidden;}
.testimonials .MuiContainer-maxWidthLg{max-width: 1300px}
.testimonials .section-title{max-width: 570px;text-transform: capitalize;padding-bottom: 16px;}
.testimonials .testimonials-content{padding-left: 26px;}
.testimonials .testimonials-mockup{margin-top: 80px;}
.testimonials .block-layer {position: relative;height: 380px;z-index: 0;}
.testimonials .block-layer .circle {width: 135px;height: 135px;border-radius: 50%;position: absolute;z-index: -1;}
.testimonials .block-layer .circle.top {background-color: #C0E8EC;top: -56px;left: -10px;}
.testimonials .block-layer .circle.bottom {background-color: #FFF0F0;bottom: -60px;right: -15px;}
.testimonials .color-layer {position: relative;display: flex;align-items: center;justify-content: center;height: 100%;}
.testimonials .custom-grid{display: flex;align-items: center;gap: 20px;}
.testimonials .custom-grid .inner-grid{display: flex;align-items: center;gap: 14px;flex-direction: column;}
.testimonials .custom-grid img{border: 6px solid #FFFFFF;border-radius: 10px;display: block;max-width: 100%;}
.testimonials .slider-block .MuiTypography-body1{color: #333333;line-height: 26px;padding-bottom: 14px;}
.testimonials .slider-block .text-block{max-width: 580px;}
.testimonials .slider-block{position: relative;}
.testimonials .slider-block::before{content: '';position: absolute; width: 62px;height: 62px;background-image: url('../images/left-quote-ic.svg');background-position: center;background-repeat: no-repeat;top: -36px;left: -78px;}
.testimonials .slider-block::after{content: '';position: absolute; width: 62px;height: 62px;background-image: url('../images/right-quote-ic.svg');background-position: center;background-repeat: no-repeat;bottom: 25px;right: -80px;}

.dymond-slider .slick-dots button::before{width: 16px;height: 16px;border-radius: 4px;background-color: #ADE1E8;font-size: 0;opacity: 1;transition: all 300ms ease;}
.dymond-slider .slick-dots .slick-active button::before{background-color: #0097A9;transform: rotate(45deg);}
.dymond-slider .slick-dots{bottom: -42px;}



/* Join Us
--------------------------------------------------------------------------------------------------------*/
.join-us{ padding: 92px 0; overflow: hidden;}
.join-us .section-title{text-transform: capitalize;padding-bottom: 12px;max-width: 614px;}
.join-us .section-info{padding-bottom: 24px;}
.join-us .block-layer {position: relative;width: 350px;height: 460px;z-index: 0;margin-left: 100px;}
.join-us .block-layer .circle {width: 135px;height: 135px;border-radius: 50%;position: absolute;z-index: -1;}
.join-us .block-layer .circle.top {background-color: #C0E8EC;top: -50px;left: -50px;}
.join-us .block-layer .circle.bottom {background-color: #FFF0F0;bottom: -70px;right: -50px;}
.join-us .color-layer {background-color: #DEF1F3;border-radius: 10px;display: flex;align-items: center;justify-content: center;position: relative;}
.join-us .block-layer .main-mockup{display: block;}
.join-us .form{padding-bottom: 24px;max-width: 614px;}



/* know more
--------------------------------------------------------------------------------------------------------*/
.know-more{padding: 62px 0; overflow: hidden; background: url('../images/primary-mask.png') no-repeat center; background-size: 100% 100%;}
.know-more .section-title{color: #FFFFFF;text-align: center;text-transform: capitalize;padding-bottom: 22px;}
.know-more .MuiButton-outlined{display: block;margin: 0 auto;border-color: #FFFFFF;color: #FFFFFF;max-width: 137px; text-align: center;}
.know-more .MuiButton-outlined:hover,
.know-more .MuiButton-outlined:focus{border-color: #FFFFFF; background-color: rgba(0, 0, 0, 0.08);}
.know-more .MuiButton-outlined:hover{border-color: #FFFFFF; background-color: rgba(0, 0, 0, 0.08); color: #0097A9;}


/* why us
--------------------------------------------------------------------------------------------------------*/
.why-us{padding: 82px 0 30px; overflow: hidden; background-color: #EFFAFC;}
.why-us .MuiContainer-maxWidthLg{max-width: 1324px;}
.why-us .section-title{padding-bottom: 0;}
.why-us .section-info{padding-bottom: 12px;font-weight: 700;font-size: 24px;line-height: 44px;}
.why-us ul li{position: relative;padding-left: 36px; display: block;}
.why-us ul li::before{position: absolute;left: 0;}
.why-us ul li ul{margin-top: 10px;}
.why-us ul li ul li{padding-left: 14px;margin-bottom: 5px;}
.why-us ul li ul li::before{background-image: none;width: 6px;height: 6px;background-color: #0097A9;border-radius: 50%; top: 8px;}

.why-us .block-layer {position: relative;width: 100%;max-width: 535px; height: 380px;z-index: 0;}
.why-us .block-layer .circle {width: 135px;height: 135px;border-radius: 50%;position: absolute;z-index: -1;}
.why-us .block-layer .circle.top {background-color: #C0E8EC;top: -50px;left: -50px;}
.why-us .block-layer .circle.bottom {background-color: #FFF0F0;bottom: -50px;right: -50px;}
.why-us .color-layer {background-color: transparent;border-radius: 10px;position: relative;display: flex;align-items: center;justify-content: center;}
.why-us .frame-block {padding: 12px 14px;}
.why-us .frame-block .svg-wrap {margin-right: 0;margin-bottom: 4px;}
.why-us .frame-block.heart {-webkit-box-orient: vertical;-webkit-box-direction: normal;    -ms-flex-direction: column;        flex-direction: column;text-align: center;top: 80px;left: -50px;}
.why-us .frame-block.mental {-webkit-box-orient: vertical;-webkit-box-direction: normal;    -ms-flex-direction: column;        flex-direction: column;text-align: center;bottom: 80px;right: -50px;}
.why-us .block-layer .main-mockup{box-shadow: 20px 4px 33px rgba(155, 182, 213, 0.16);border-radius: 10px;display: block;}
.why-us .frame-block.calendar{top: -40px;left: 170px;z-index: 1;}
.why-us .frame-block.doc-laptop{bottom: 50px;left: -40px;z-index: 1;}
.why-us .frame-block.rural{top: 50px;right: -30px;}
.why-us .frame-block.payment{bottom: -40px;right: 50px;}
.why-us .why-us-mockup{margin-top: 54px;margin-left: -50px;}





/* access service
--------------------------------------------------------------------------------------------------------*/
.access-service{padding: 92px 0 30px; overflow: hidden;}
.access-service .MuiContainer-maxWidthLg{max-width: 1288px;}
.access-service .access-service-mockup{padding-left: 38px;}
.access-service .section-title{text-align: center;text-transform: capitalize;padding-bottom: 68px;}
.access-service .block-layer {position: relative;width: 100%;max-width: 500px; height: 416px;z-index: 0;}
.access-service .color-layer {background-color: #DEF1F3;border-radius: 10px;display: flex;align-items: center;justify-content: center; position: relative;}
.access-service .block-layer .circle {width: 135px;height: 135px;border-radius: 50%;position: absolute;z-index: -1;}
.access-service .block-layer .circle.top {background-color: #C0E8EC;top: -50px;left: -50px;}
.access-service .block-layer .circle.bottom {background-color: #FFF0F0;bottom: -50px;right: -50px;}
.access-service .block-layer .main-mockup{display: block;border-radius: 10px;transform: matrix(1, 0.08, -0.08, 1, 0, 0);max-width: 100%;}
.access-service .card{background: #FFFFFF;box-shadow: 4px 2px 20px rgba(0, 0, 0, 0.12);border-radius: 10px;padding: 24px 24px 30px;margin-bottom: 32px;}
.access-service .card:hover{background-color: #EFFAFC;}
.access-service .card .MuiTypography-body2{font-weight: 600;font-size: 16px;line-height: 24px;}
.access-service .card .MuiTypography-h6{font-weight: 600;font-size: 20px;line-height: 24px;padding-bottom: 17px;}
.access-service .card .MuiTypography-body1{font-size: 16px;line-height: 24px;color: #333333;}





/* news
--------------------------------------------------------------------------------------------------------*/
.news{padding: 120px 0 80px; overflow: hidden;}
.news .MuiContainer-maxWidthLg{max-width: 1308px;}
.news .section-title{text-align: center;text-transform: capitalize;padding-bottom: 32px;}
.news .slider-block .card{background: #FFFFFF;box-shadow: 0px 0px 15px 1px rgba(38, 50, 56, 0.1);border-radius: 4px;padding: 24px;height: 100%;display: flex;flex-direction: column;}
.news .slider-block .card-outer{padding: 16px;height: 100%;}  
.news .slider-block .card .date{font-size: 14px;line-height: 20px;color: #9E9E9E;padding-bottom: 8px;}
.news .slider-block .card .MuiTypography-body1{font-weight: 600;font-size: 16px;line-height: 22px;color: #424242;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 2; /* number of lines to show */line-clamp: 2;-webkit-box-orient: vertical;}
.news .slider-block .card .MuiDivider-root{margin-top: 10px;margin-bottom: 18px;background-color: #E0E0E0;}
.news .slider-block .card .MuiTypography-body2{font-size: 16px;line-height: 18px;color: #424242;margin-bottom: 12px;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 3; /* number of lines to show */line-clamp: 3;-webkit-box-orient: vertical;}
.news .slider-block .card button{display: flex;align-items: center;color: #0097A9;font-size: 16px;line-height: 20px;text-transform: capitalize;width: max-content;cursor: pointer;background: none;border: none;}
.news .slider-block .card button:hover,.news .slider-block .card button:focus{text-decoration: underline;color: #006976;}
.news .slider-block .card button>img{display: block;margin-top: 2px;margin-bottom: -2px;}
.news .slider-block .card .link-block{margin-top: auto;}

.news .dymond-slider .slick-track{display: flex;}
.news .dymond-slider .slick-slide{height: inherit;}
.news .dymond-slider .slick-slide > div{height: 100%;}
/* faq
--------------------------------------------------------------------------------------------------------*/
.faq{padding: 96px 0 56px;overflow: hidden;}
.faq .section-title{text-align: center;text-transform: uppercase;padding-bottom: 52px;}
.faq .MuiAccordion-root.primary-accordion .MuiAccordionSummary-root{background-color: #FFFFFF;border: 1px solid #D4EAEE;border-radius: 10px;}
.faq .MuiAccordion-root.primary-accordion.Mui-expanded .MuiAccordionSummary-root{background-color: #EFFAFC;border-color: transparent;border-radius: 10px 10px 0 0;}
.faq .MuiAccordion-root.primary-accordion.Mui-expanded .MuiAccordionSummary-root .MuiTypography-root{color: #333333; text-transform: capitalize;}
.faq .MuiAccordion-root.primary-accordion.Mui-expanded .MuiAccordionSummary-expandIconWrapper svg{fill: #666666;}
.faq .MuiPaper-root.MuiAccordion-root.primary-accordion .MuiAccordionDetails-root{padding: 16px;}
.faq .MuiContainer-maxWidthLg{max-width: 878px;}

.faq .MuiAccordion-root.primary-accordion .MuiAccordionSummary-content{overflow: hidden; text-transform: capitalize;}
.faq .MuiAccordion-root.primary-accordion .MuiAccordionSummary-content p.text-overflow{width: 100%;white-space: nowrap;max-width: 760px; text-overflow: ellipsis;overflow: hidden;}


/* footer
--------------------------------------------------------------------------------------------------------*/
.footer{padding: 28px 0 44px; overflow: hidden; background-color: #0097A9;}
.footer .MuiContainer-maxWidthLg{max-width: 1308px;}
.footer .logo-block{margin-bottom: 20px;}
.footer .portal-links a,
.footer .portal-links p{display: flex;align-items: flex-start;color: #FFFFFF;font-size: 18px;line-height: 20px;max-width: 280px;}
.footer .portal-links a{margin-bottom: 22px; transition: all 300ms ease;}
.footer .portal-links a:hover,.footer .portal-links a:focus{text-decoration: underline;}
.footer .portal-links p{line-height: 30px;}
.footer .portal-links a > img,
.footer .portal-links p  img{display: block;margin-right: 6px;width: 20px;height: 20px;}
.footer .portal-links p img{margin-top: 4px;margin-bottom: -4px;}

.footer .social-links{display: flex;align-items: center;gap: 11px;}
.footer .social-links a{display: flex;align-items: center;justify-content: center;border-radius: 50%;transition: all 300ms ease; width: 44px; height: 44px;}
.footer .social-links a:hover,.footer .social-links a:focus{background-color: rgba(0, 0, 0, 0.08);}
.footer .social-links a img{display: block;}
.footer .conditions{display: flex;align-items: center;color: #FFFFFF;}
.footer .conditions a{display: block;color: #FFFFFF;font-size: 18px;line-height: 30px; transition: all 300ms ease;cursor: pointer;}
.footer .conditions p{display: block;color: #FFFFFF;font-size: 18px;line-height: 30px; transition: all 300ms ease;}
.footer .conditions a:hover,.footer .conditions a:focus,.footer .copyright-wrapper a:hover,.footer .copyright-wrapper a:focus{text-decoration: underline;}

.footer .copyright{font-weight: 400;font-size: 18px;line-height: 30px;color: #FFFFFF;display: flex;margin-bottom: 3px;}
.footer .copyright em{font-size: 10px;line-height: 12px;display: block;}

.footer .link-wrapper{display: flex;flex-direction: column;margin-top: 14px;height: 100%;}
.footer .social-links, .footer .conditions, .footer .copyright{margin-left: auto;}
.footer .conditions{margin-top: auto;}
.footer .conditions span{display: block;margin: 0 15px;}

.footer .copyright-wrapper {display: flex;gap: 10px;justify-content: center;align-items: center;}
.footer .copyright-wrapper a {display: block;color: #FFFFFF;font-size: 12px;line-height: 30px;transition: all 300ms ease;cursor: pointer;}
.footer .copyright-wrapper span {font-size: 12px !important;line-height: 30px;color: #FFFFFF;font-weight: 400;display: flex;}

.footer-modal{position: absolute;top: 50%;left: 50%;transform: translate(-50%,-50%);background: #FFFFFF;box-shadow: 0px 8px 24px 1px rgba(0, 0, 0, 0.16) !important;border-radius: 10px;
  padding: 20px 20px 33px; max-width: 85%; width: 100%;
}
.footer-modal .MuiTypography-h6{font-weight: 500;font-size: 16px;line-height: 20px;color: #333333;}
.footer-modal .MuiTypography-body1{font-weight: 400;font-size: 14px;line-height: 20px;color: #666666;}
.footer-modal .modal-header{display: flex;align-items: center;justify-content: space-between;margin-bottom: 15px;}
.footer-modal .btn-close{margin-right: -12px;margin-top: -4px; min-width: 0; padding: 2px;border-radius: 50%;}
.footer-modal .modal-body{overflow: auto;max-height: calc(100vh - 200px);margin-right: -20px; margin: 0 -20px; padding: 10px 20px;}

.terms-and-conditions ol{ counter-reset: item; font-size: 14px; }
.terms-and-conditions li{ display: block; font-weight: 500; color: #3e3e3e; }
.terms-and-conditions li:before { content: counters(item, ".") ". "; counter-increment: item }
.terms-and-conditions ol ol{ padding-left: 16px; }

.terms-and-conditions-inner-list{ margin-bottom: 10px; }
.terms-and-conditions-inner-list li{ margin-top: 5px; font-weight: 300 !important; color: #666666 !important; }

.terms-and-conditions-title{ font-weight: 500; margin-bottom: 10px; font-size: 14px; color: #3e3e3e;}

/* All Media
--------------------------------------------------------------------------------------------------------*/
@media (max-width: 1535px){
  .hero .hero-mockup .frame-block.bill {right: -20px;}

  .practice-with-us .block-layer .main-mockup{display: block; max-width: 100%;}
  .practice-with-us .practice-mockup-block{margin-left: 40px;}

}
@media (max-width: 1199px){
  .header .login-btn{margin-left: auto;}
  .header .header-inner-block{padding: 12px 0;}

  .hero{height: auto; padding-bottom: 40px;}
  .hero .hero-content{text-align: center;padding-top: 20px;}
  .hero .hero-content h2{max-width: 600px;margin: 0 auto;font-size: 50px;line-height: 60px;}
  .hero .hero-content h6{margin: 0 auto;}
  .hero .hero-content .find-link{justify-content: center;}
  .hero .btn-groups{justify-content: center;}
  .hero .hero-mockup{max-width: 623px; margin: 0 auto;}
  .hero .hero-mockup .hero-frame{height: 300px;}
  .hero .hero-mockup .circle-layer{width: 420px;height: 420px; border-width: 40px;}
  .hero .frame-block{padding: 10px;}
  .hero .hero-mockup .frame-block.fear{padding: 10px;}
  .hero .hero-mockup .frame-block.online {top: 75px;}

  .about-us .block-layer{margin: auto;height: 360px;width: 360px;}
  .about-us .color-layer{height: 100%;}
  .about-us .block-layer .main-mockup{max-width: 100%;display: block;}
  .about-us .block-layer .circle.top{top: -70px;}
  .about-us .block-layer .circle.bottom {bottom: -70px;}
  .about-us .frame-block{padding: 12px;}
  .about-us .section-title{padding-top: 0;}

  .comparison-table{padding: 60px 0 30px;}

  .practice-with-us{padding: 30px 0 60px;}
  .practice-with-us .frame-block{padding: 20px;}
  .practice-with-us .block-layer{max-width: 480px;margin: 0 auto;}
  .practice-with-us .practice-mockup-block{text-align: center;margin-left: auto;}
  .practice-with-us .color-layer{height: 100%;}
  .practice-with-us .section-info{max-width: 600px;margin: 0 auto;}
  .practice-with-us .accordion-block{padding-left: 0; padding-top: 60px; max-width: 600px; margin: 0 auto;}

  .other-frameworks .logo-block{padding: 20px 0;}

  .testimonials{padding: 60px 0;}
  .testimonials .testimonials-mockup{margin-top: 56px;}
  .testimonials .block-layer{max-width: 500px; margin: 0 auto;}
  .testimonials .block-layer .circle.top{left: -30px;}
  .testimonials .block-layer .circle.bottom{right: -30px;}
  .testimonials .testimonials-content{padding-left: 0;max-width: 600px; margin: 40px auto 0;}
  .testimonials .slider-block::after{right: -60px;}

  .join-us .join-us-form{max-width: 600px;margin: 0 auto;}
  .join-us .section-title{text-align: center;}
  .join-us .section-info{text-align: center;}
  .join-us .submit-btn{margin: 0 auto;display: block;}
  .join-us .block-layer .main-mockup{max-width: 100%;}
  .join-us .block-layer{margin: 0 auto;}
  .join-us .join-us-form{padding-top: 70px;}

  .know-more{padding: 40px 0;}

  .why-us{padding: 60px 0 30px;}
  .why-us .why-us-mockup{margin: auto;margin-top: 40px;}
  .why-us .block-layer{max-width: 600px;height: 400px;width: 100%;}
  .why-us .block-layer .circle.top{top: -30px;left: -20px;}
  .why-us .block-layer .circle.bottom{bottom: -30px;right: -20px;}
  .why-us .frame-block.calendar{top: 0;}
  .why-us .frame-block.doc-laptop{left: -24px;}
  .why-us .frame-block.rural{right: -20px;}
  .why-us .frame-block.payment{bottom: 0;}
  .why-us .about-us-content{padding-left: 0;padding-top: 20px;}
  .why-us .section-title{text-align: center;}
  .why-us .section-info{text-align: center;}
  .why-us ul li{max-width: none;}

  .access-service{padding: 30px 0;}
  .access-service .block-layer{height: 350px;}
  .access-service .color-layer{height: 100%;}
  .access-service .block-layer .main-mockup{height: 98%;width: 95%;}
  .access-service .access-service-mockup{padding-left: 10px;}
  .access-service .card{padding: 16px;z-index: 1;position: relative;margin-bottom: 16px;}

  .news{padding: 30px 0 60px;}

  .faq{padding: 60px 0;}

  

  
}
@media (max-width: 899px){

  .hero .hero-content h2{font-size: 32px;line-height: 40px;}
  .hero .hero-content h6{font-size: 18px;line-height: 24px;}
  .hero .hero-content .find-link{font-size: 14px;line-height: 20px;}

  .about-us{padding-bottom: 30px;}
  .about-us .about-us-content{padding-left: 0;padding-top: 60px;}
  .about-us ul li{max-width: none;}

  .how-it-works{padding-top: 30px;}

  .comparison-table{padding: 60px 0 30px;}

  .access-service .access-service-mockup{padding-left: 0;}
  .access-service .block-layer{margin: 0 auto;}
  .access-service .access-service-content{padding-top: 40px;}

  .footer .social-links, .footer .conditions, .footer .copyright-wrapper{margin: 0 auto 16px;}
  .footer .logo-block a{display: block;margin: 0 auto;max-width: max-content;}
  .footer .portal-links a,.footer .portal-links p{justify-content: center;max-width: none;}
  .footer .portal-links p{max-width: 260px !important;margin: 0 auto;font-size: 16px;line-height: 26px;}


}
@media (max-width: 599px){

  .header .header-inner-block{padding: 10px 0;}
  .header .navbar-brand img{height: 54px;}

  .hero .btn-groups{flex-direction: column; max-width: 500px; margin: 30px auto 0;}
  .hero .btn-groups .MuiButton-outlined{max-width: 200px;width: 100%;}
  .hero .hero-mockup .circle-outer-layer{width: 550px;height: 550px;}
  .hero .hero-mockup .frame-block.online{left: -12px;top: 110px;}
  .hero .hero-mockup .frame-block.fear{bottom: 110px;}
  .hero .hero-mockup .frame-block.bill{bottom: 160px; right: -12px;}
  .hero .hero-mockup .hero-frame{height: auto;}

  .about-us .block-layer{max-width: 300px;width: 100%;}
  .about-us .block-layer .circle{width: 100px;height: 100px;}
  .about-us .block-layer .circle.top{top: -40px;}
  .about-us .block-layer .circle.bottom{bottom: -40px;}
  .about-us .frame-block.heart{left: -24px;top: 20px;}
  .about-us .frame-block.mental{right: -24px;bottom: 10px;}

  .practice-with-us .block-layer .circle{width: 100px;height: 100px;}
  .practice-with-us .block-layer{height: 250px;}
  .practice-with-us .frame-block{padding: 10px;}
  .practice-with-us .section-info{padding-bottom: 60px;}
  .practice-with-us .frame-block.medication{right: -8px;}
  .practice-with-us .frame-block.conference{left: 30%;top: 0;}
  .practice-with-us .frame-block.clock{bottom: 0;left: 15px;}
  .practice-with-us .block-layer .circle.top{top: -10px;}
  .practice-with-us .block-layer .circle.bottom{bottom: -10px;}

  .testimonials .color-layer{height: 100%;}
  .testimonials .block-layer{height: 250px;}
  .testimonials .block-layer .circle{width: 100px;height: 100px;}
  .testimonials .block-layer .circle.top{top: -40px;}
  .testimonials .block-layer .circle.bottom{bottom: -40px;}
  .testimonials .custom-grid{gap: 8px;}
  .testimonials .custom-grid .inner-grid{gap: 6px;}
  .testimonials .slider-block::before{left: -65px;}

  .join-us .color-layer{height: 100%;}
  .join-us .block-layer{height: 300px;width: 280px;}
  .join-us .block-layer .circle{width: 100px;height: 100px;}
  .join-us .block-layer .circle.top{left: -30px;top: -40px;}
  .join-us .block-layer .circle.bottom{right: -30px;bottom: -40px;}

  .about-us .block-layer{height: 280px;}
  .why-us .block-layer .circle.top{top: 0;}
  .why-us .block-layer .circle.bottom{bottom: 0;}
  .why-us .frame-block.calendar{left: 100px;}

  .access-service .block-layer .circle{width: 100px;height: 100px;}
  .access-service .block-layer .circle.top{left: -30px;top: -30px;}
  .access-service .block-layer .circle.bottom{right: -30px;bottom: -30px;}
  .footer .conditions{flex-direction: column;gap: 10px;}
  .footer .conditions span{display: none;}
  .footer .copyright-wrapper{margin-bottom: 0;flex-direction: column;gap: 0px;}
  .footer .link-wrapper{margin-top: 40px;}
  .footer .social-links a img{height: 24px;width: 24px;}

  .footer-modal{max-width: calc(100% - 24px);}
}